<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <template #toolbar>
        <dsb-substrates-sync
          v-if="hadAdminRole"
          @sync:success="$_refreshData"
        />
      </template>

      <vbt-table
        :headers="headers"
        :items="substrates"
      />

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
        :total-count="substratesTotalCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import { AuthNamespace, AutocompleteNamespace, AutocompleteActionTypes } from '@store/types';

import {
  namespace as SubstratesNamespace,
  ActionTypes as SubstratesActionTypes,
} from '../store.types';

import DsbSubstratesSync from '../_components/DsbSubstratesSync.vue';

const dataLoadingOptions = {
  getterName: SubstratesActionTypes.GET_SUBSTRATES,
  contentName: 'substrates',
};
const paginationOptions = { contentName: 'substrates' };
const searchOptions = {
  initialSearchDataFields: [
    'name',
  ],
};

const headers = Object.freeze([
  { text: 'Name', value: 'name' },
  { text: 'Abbreviation', value: 'abbreviation' },
  { text: 'Paper Thickness', value: 'paperThickness' },
]);

export default {
  name: 'DsbSubstrates',

  components: {
    DsbSubstratesSync,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState(AutocompleteNamespace, ['substrateAutocomplete']),
    ...mapState(SubstratesNamespace, ['substrates', 'substratesTotalCount']),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),

    searchFieldsConfig() {
      return [
        {
          value: 'name',
          label: 'Name',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.substrateAutocomplete,
          callback: this.getSubstrateAutocomplete,
          flex: 'sm4',
        },
      ];
    },
  },

  methods: {
    ...mapActions(AutocompleteNamespace, {
      getSubstrateAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_SUBSTRATE_AUTOCOMPLETE,
        { query },
      ),
    }),

    ...mapActions(SubstratesNamespace, [SubstratesActionTypes.GET_SUBSTRATES]),
  },
};
</script>
